@import './variables';

.App {
  text-align: center;
}

.App-header {
  background-color: $background-dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-radius: 15px;
  padding: 20px;
}

