@import './variables';
html{
  background: linear-gradient(145deg, $background-dark, $secondary-color);
  background-size: 10000% 10000% ;
  animation: gradient-animation 10s ease-in-out infinite;
  
}

body {
  width: 100vw;
  height: 100vh;display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  font-family: $body-font;
  color: $text-color;
  overflow-x: hidden;
}

nav {
  min-width: 90vw;
  height: 6vh;
  margin-top: 2%;
  display: grid;
  grid-template-columns: 1fr auto 1fr; // Logo na lewo, linki na środku, Human na prawo
  align-items: center;
  border-radius: 30px;
  backdrop-filter: blur(8px);
  background: rgba(243, 239, 224, 0.08);
  border: 1px solid rgba(243, 239, 224, 0.2);

  .Logo {
    justify-self: start; // Maksymalnie na lewo
    padding-left: 20px;

    a {
      color: black;
      text-decoration: none;
      font-weight: bold;
      font-size: 1.5em;
    }

    a:hover {
      color: $hover-color;
      text-shadow: 0 0 8px rgba(244, 34, 114, 0.8);
      transform: scale(1.5);
    }
  }

  .NavLinks {
    justify-self: center; // Wyśrodkowanie linków
    a {
      color: $link-color;
      text-decoration: none;
      margin: 0 15px;
      font-weight: bold;
      transition: all 0.3s ease;
      position: relative;
    }

    a:hover {
      color: $hover-color;
      text-shadow: 0 0 8px rgba(244, 34, 114, 0.8);
      transform: scale(1.1);
    }
  }

  .HumanLink {
    justify-self: end; // Maksymalnie na prawo
    padding-right: 20px;

    a {
      color: #008000; // Zielony kolor
      text-decoration: none;
      font-weight: bold;
      transition: all 0.3s ease;
      position: relative;
    }

    a:hover {
      color: rgb(225, 255, 225); // Ciemniejszy zielony po najechaniu
      text-shadow: 0 0 8px rgba(177, 250, 177, 0.8);
      transform: scale(1.1);
    }
  }
}


.mainDivContainer {
  margin-top: 2%;
  display: flex;
  justify-content: center;
  height: 100%;
  margin-bottom: 20%;
  .mainDiv {
    text-align: center;
    padding: 40px;
    width: 60vw;
    max-width: 800px;
    background: rgba(35, 46, 33, 0.85);
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    color: $text-color;
    font-family: $heading-font;
    animation: fadeIn 2s ease-out;

    h2 {
      font-size: 3rem;
      margin-bottom: 15px;
      color: $primary-color;
      text-transform: uppercase;
      letter-spacing: 4px;
      text-shadow: 0 0 10px rgba(244, 34, 114, 0.5);
    }

    p {
      font-size: 1.2rem;
      line-height: 1.6;
      margin-top: 10px;
      color: $hover-color;
    }
  }
}

.gridStoriesDivContainer {
  display: grid;
  gap: 20px;
  height: 100%;
  padding: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.fadeIn{
  opacity: 0;
  animation: fadeIn 1s ease-out forwards; /* 'forwards' keeps the final state */
  animation-fill-mode: forwards; /* Extra insurance to maintain final state */

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.gridStoriesDiv {
  background-color: $secondary-color;
  color: $text-color;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-out;
  opacity: 0; // Start with opacity 0 for fadeIn
  animation: fadeIn 1s ease-out forwards;
  animation-fill-mode: forwards;
  
  &:hover {
    transform: translateY(-5px); // Changed to move up instead of down
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.4); // Optional: enhanced shadow on hover
  }

  h2 {
    color: #F42272;
    margin-bottom: 10px;
    font-family: 'Playfair Display', serif;
  }

  p {
    color: $text-color;
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    margin: 10px 0;
  }

  .imageContainer {
    height: 180px;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }

  .readMoreLink {
    color: #F42272;
    text-decoration: none;
    font-weight: bold;
    margin-top: auto;
    align-self: flex-start;
    position: relative;
    padding: 0.5em 1em;
    transition: all 0.3s ease;
    isolation: isolate;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: rgba(244, 34, 114, 0.1);
      border-radius: 100px;
      z-index: -1;
      transform: scale(0.95);
      opacity: 0;
      animation: pulse 5s ease-in-out infinite;
    }

    &:hover {
      color: #F3EFE0;
      text-shadow: 0 0 8px rgba(243, 239, 224, 0.7);

      &::before {
        animation-play-state: paused;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0;
  }
}




// Efekt animacji gradientu
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

body.human-page {
  background: #1a1a1a; // Ciemne tło
  color: white;        // Biały kolor tekstu
  margin: 0;
  padding: 0;
  overflow: hidden;    // Ukryj paski przewijania, jeśli potrzebne
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1; // Umieść cząsteczki w tle
}

.mainDivContainer {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 20px;
}


// Efekt pojawiania się elementu


