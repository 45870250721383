// Human.scss
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;700&display=swap');
@import './variables';
:root {
  --bg-primary: #232E21;
  --text-primary: #F3EFE0;
  --accent-purple: #bbaaf3;
  --accent-green: #008000;
  --discord-blurple: #5865F2;
}

.human-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Space Grotesk', sans-serif;
  background-color: none;
  color: var(--text-primary);
  overflow: hidden;

  &__content {
    position: relative;
    z-index: 10;
    text-align: center;
    max-width: 800px;
    padding: 2rem;
    
    h1 {
      font-size: clamp(2rem, 5vw, 4rem);
      margin-bottom: 1rem;
      font-weight: 700;
      color: var(--accent-purple);
    }

    p {
      font-size: clamp(1rem, 3vw, 1.2rem);
      line-height: 1.6;
      margin-bottom: 2rem;
    }
  }

  &__discord-bot {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(88, 101, 242, 0.1);
    border: 2px solid var(--discord-blurple);
    border-radius: 12px;
    padding: 1.5rem;
    margin-top: 2rem;
    transition: all 0.3s ease;

    &:hover {
      background-color: rgba(88, 101, 242, 0.2);
      transform: scale(1.02);
    }

    &-title {
      color: var(--discord-blurple);
      font-weight: 700;
      margin-bottom: 1rem;
    }

    &-description {
      text-align: center;
      max-width: 500px;
    }

    &-link {
      display: inline-block;
      margin-top: 1rem;
      padding: 0.5rem 1rem;
      background-color: var(--discord-blurple);
      color: white;
      text-decoration: none;
      border-radius: 8px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: lighten(#5865F2, 10%);
      }
    }
  }

  &__patch-notes {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;

    &-toggle {
      background: transparent;
      border: none;
      color: var(--text-primary);
      font-size: 1.5rem;
      cursor: pointer;
      opacity: 0.5;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 1;
      }
    }

    &-content {
      position: fixed;
      bottom: 70px;
      right: 20px;
      background-color: $background-dark;
      border: 1px solid var(--accent-purple);
      border-radius: 12px;
      padding: 1rem;
      max-width: 300px;
      max-height: 400px;
      overflow-y: auto;
      display: none;
      ul{
        color: #5865F2;
      }
      &.visible {
        display: block;
      }
    }
  }

  .latest-patch{
    color: $primary-color;
    text-shadow: 0 0 8px #5865F2;
  }

  .dates{
    color: #4e5ad7;
  }

  @media (max-width: 768px) {
    &__content {
      padding: 1rem;
    }

    &__discord-bot {
      width: 90%;
      padding: 1rem;
    }
  }
}