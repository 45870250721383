$background-dark: #232E21;
$primary-color: #F42272;
$secondary-color: #210124;
$accent-color: #F3EFE0;
$link-color: #8d1a98;
$text-color: #bbaaf3;
$hover-color: #F3EFE0;

// Czcionki
$heading-font: 'Playfair Display', serif;
$body-font: 'Roboto', sans-serif;