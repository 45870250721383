@import './variables';

.story-container {
  background-color: $background-dark;
  color: $text-color;
  font-family: $body-font;
  padding: 2rem;
  border-radius: 12px;
  max-width: 100%;
  margin: 2rem auto;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  overflow: hidden;

  /* Image styling */
  .image-container {
    position: relative;
    margin-bottom: 1.5rem;
    overflow: hidden;

    .image-wrapper {
      position: relative; /* Required for the vignette pseudo-element */
      display: inline-block;
      border-radius: 30px; /* Match the image's border-radius */
      overflow: hidden; /* Ensure the vignette doesn't extend beyond the rounded corners */
    
      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
        border-radius: 30px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
        border: 4px solid $background-dark;
        transform: translateY(-10px);
      }
    
      /* Add vignette effect */
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(
          circle,
          #2101246c 60%,
          #8d1a9878 100%
        );
        pointer-events: none; /* Ensures it doesn't interfere with interactions */
        z-index: 10; /* Ensure it sits on top of the image */
      }
    }
    
    

    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      border-radius: 12px;
      background: linear-gradient(
        to bottom right,
        rgba(244, 34, 114, 0.2),
        rgba(34, 34, 34, 0.4)
      );
      z-index: -1;
    }
  }

  /* Title styling */
  h2 {
    font-family: $heading-font;
    font-size: 2rem;
    color: $primary-color;
    text-align: center;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  /* Content styling */
  .markdown-content {
    font-size: 1.2rem;
    line-height: 1.8;
    color: $accent-color;
    font-family: $body-font;

    /* Enhance Markdown header styles */
    h1, h2, h3 {
      color: $primary-color;
      font-family: $heading-font;
      margin-bottom: 1rem;
      border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    }

    ul, ol {
      margin-left: 2rem;
      padding-left: 1rem;
      list-style: disc;
      color: $text-color;
    }

    a {
      color: $link-color;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        color: $hover-color;
      }
    }

    blockquote {
      border-left: 4px solid $primary-color;
      padding-left: 1rem;
      color: $secondary-color;
      background-color: rgba(255, 255, 255, 0.05);
      font-style: italic;
      margin: 1rem 0;
    }

    p {
      margin-bottom: 1.5rem;

      &:first-letter {
        font-size: 2rem;
        font-weight: bold;
        color: $primary-color;
        font-family: $heading-font;
      }
    }
  }
}
